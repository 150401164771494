













































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import dayjs from 'dayjs';

@Component
export default class App extends Vue {
  inputData: any = {
    startTime: '',
  };
  data: any = {};
  loaded = false;
  saved = false;
  hash = '';

  startTimeMenu = false;
  endTimeMenu = false;

  rules = {
    required: (value: string) => !!value || 'Benötigtes Feld',
  };

  get formatedUid(): string {
    let id = this.data.uid.toString();

    while (id.length < 6) {
      id = `0${id}`;
    }

    return id;
  }

  get formatedDate() {
    return dayjs(this.data.dateAssemblyFrom).format('DD.MM.YYYY');
  }

  async mounted() {
    this.hash = location.href.substr(location.href.indexOf('#/') + 2);
    const { data } = await axios.post(`${process.env.VUE_APP_DOMAIN_API}/getChecklistDetail`, {
      hash: this.hash,
    });

    this.data = data;

    if (data != null) {
      this.inputData.contactPerson = data.contactPerson;
    }
    this.loaded = true;
  }

  async SentForm() {
    if ((this.$refs['form'] as any).validate()) {
      const response = await axios.post(`${process.env.VUE_APP_DOMAIN_API}/saveChecklist`, {
        token: this.hash,
        input: this.inputData,
      });

      if (response.data == true) {
        this.saved = true;
      }
    }
  }
}
